<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Actividad de usuarios</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
              <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                  <div class="input-group">
                    <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-outline-primary">Buscar</button>
                      <button
                        type="button"
                        v-on:click="newSearch()"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >Búsqueda avanzada</button>                
                      <search-usuario v-on:search="searchItems" v-bind:item="search"></search-usuario>
                    </div>
                  </div>                
                </form>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <td>Usuario</td>
                      <td>Nombre completo</td>
                      <td>Correo electrónico</td>
                      <td>Tipo</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items">
                      <td><a href="#" v-on:click="detalles(item._id, item.usu_nmbre + ' ' + item.usu_aplldo)">{{ item.usu_cdgo }}</a></td>
                      <td>{{ item.usu_nmbre + " " + item.usu_aplldo }}</td>
                      <td>{{ item.usu_email }}</td>
                      <td>{{ item.tus_nmbre }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import SearchUsuario from "./SearchUsuario.vue";

export default {
  components: {
    //VIcon,
    SearchUsuario
  },
  data(){
    return{
      items: [],
      keywords:'',
      search: {},
      filter: false, // Items are filtered
    }
  },
  filters: {

  },
  computed: {

  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
    }
    this.searchItems(this.search);
  },
  methods: {
    searchItems(p){
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
      } else {
        p = {};
      }

      this.message = 'Buscando usuarios';
      p.cmp_id = this.$store.state.company;
      let uri = '/usuarios/search';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
      .then(response => {
        console.log(response);
        this.items = response.data;
        this.search = p;
        this.filter = true;
      })
      .catch(err => {
        console.log(err);
        this.message = '¡Error al buscar los usuarios' + err;
      });
    },
    closeSearch(){
      this.filter = false;
      this.search = {};
      this.keywords = '';
      this.items = [];
      this.searchItems();
    },
    newSearch () {
      this.search = {};
    },
    detalles(id, nombre){
      this.$router.replace({ name: 'DetailActivity', params: { usu_id: id, nombre: nombre, search: this.search } });
    }
  }
}
</script>